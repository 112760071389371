import { isValidCNPJ, isValidCPF } from "@brazilian-utils/brazilian-utils";
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Card,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { create } from 'react-modal-promise';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../Button';
import { CurrencyTextField } from '../CurrencyTextField';
import Document from '../Document';
import { LoadingButton } from '../LoadingButton';
import { Select } from '../Select';
import { TextField } from '../TextField';
import { getPersonByDocumentInSerpro } from '../../services/bureauService';
import { getCRMCountriesPromise } from "../../services/crmCountryService";
import {
    CardHeaderTitleStyled,
    FormControlLabelStyled,
    FormHelperTextStyled,
    FormLabelInfoStyled,
    FormLabelStyled,
} from '../../styles/styles';
import { displayErrorMessage } from '../../utils/errorMessage';
import { toBase64 } from "../../utils/fileUtils";
import * as masks from "../../utils/masks";
import { CRMCountryOptions } from "../CRMCountryOptions";
import { DialogTitleStyled } from '../DeleteModal/styles';
import { LoadingBox } from '../LoadingBox';
import parse from 'date-fns/parse';
import { getContactReasonPromise } from "../../services/clientService";
import { removeSpecialCharactersAndAccents } from "../../utils/stringUtils";
import { registrationUpdateCompanyAssociateAdd } from "../../services/updateService";

const validationSchema = yup.object().shape({
    id: yup.string().nullable(),
    typeId: yup.string().required(),
    name: yup.string()
        .min(2)
        .required(),
    taxId: yup.string()
        .transform(masks.cpfCnpjMask.transform)
        .when('typeId', {
            is: "1",
            then: yup.string()
                .required()
                .test({
                    name: "validateCPF",
                    test: function (value) {
                        if (isValidCPF(value!)) {
                            return true;
                        }
                        return this.createError({
                            path: this.path,
                        })
                    }
                }),
            otherwise: yup.string()
                .required()
                .test({
                    name: "validateCNPJ",
                    test: function (value) {
                        if (isValidCNPJ(value!)) {
                            return true;
                        }
                        return this.createError({
                            path: this.path,
                        })
                    }
                })
        }),
    shareCapital: yup.string()
        .nullable()
        .test({
            name: "shareCapital",
            message: "validations.percentageBetween0And100",
            test: function (value) {
                const percentage = parseFloat(value!);
                return percentage > 0 && percentage <= 100;
            }
        }),
    legalRepresentative: yup.string()
        .when("typeId", {
            is: "1",
            then: yup.string().required()
        }),
    usPerson: yup.string()
        .when("typeId", {
            is: "1",
            then: yup.string().required()
        }),
    nif: yup.string()
        .when(["usPerson", "typeId"], {
            is: (usPerson:string, typeId:string) => usPerson === "1" && typeId === "1",
            then: yup.string().required()
        }),
    isPep: yup.string()
        .when("typeId", {
            is: "1",
            then: yup.string().required()
        }),
    isPepExplain: yup.string()
        .when(["isPep", "typeId"], {
            is: (isPep:string, typeId:string) => isPep === "1" && typeId === "1",
            then: yup.string().required()
        }),
    identificationProof: yup.mixed()
        .when(["typeId", "shareCapital"], {
            is: (typeId:string, shareCapital:string) => typeId === "1" && parseFloat(shareCapital) >= 25,
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
    isNational: yup.string()
        .when("typeId", {
            is: "2",
            then: yup.string().required()
        }),
    country: yup.string()
        .when("isNational", {
            is: "2",
            then: yup.string().required()
        }),
    contractProof: yup.mixed()
        .when(["typeId", "shareCapital"], {
            is: (typeId:string, shareCapital:string) => typeId === "2" && (parseFloat(shareCapital) >= 10),
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                })
        }),
});

interface RegistrationUpdatePartnerAddModalProps {
    open:boolean ;
    onResolve: ()=> void ,
    onReject:()=> void 
}

const RegistrationUpdatePartnerAddModal : React.FC<RegistrationUpdatePartnerAddModalProps> = ({ open, onResolve, onReject }) => {
    const { t } = useTranslation();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [bureauState, setBureauState] = useState({ loading: false, error: false });
    const [loading, setLoading] = useState<boolean>(true);
    const [countriesOptions, setCountriesOptions] = useState([]);
    const [uploading, setUploading] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors }, reset, register, trigger, getValues, setValue } = useForm<FieldValues>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            id: "",
            typeId: "1",
            name: "",
            taxId: "",
            shareCapital: "",
            legalRepresentative: "",
            usPerson: "",
            nif: "",
            isPep: "",
            isPepExplain: "",
            identificationProof: "",
            isNational: "",
            country: "",
            contractProof: "",
            partnerTypeId: "1",
        }
    });

    const typeId = useWatch({
        control,
        name: "typeId",
    });

    const usPerson = useWatch({
        control,
        name: "usPerson",
    });

    const isPep = useWatch({
        control,
        name: "isPep",
    });

    const shareCapital = useWatch({
        control,
        name: "shareCapital",
    });

    const isNational = useWatch({
        control,
        name: "isNational",
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const [countriesOptions, contactReason] = await Promise.all([
                    getCRMCountriesPromise(),
                    getContactReasonPromise()
                ]);
                setCountriesOptions(countriesOptions.data);
                const { partnerTypeId } = contactReason.data;

                reset({
                    ...getValues(),
                    partnerTypeId: partnerTypeId
                })
                setLoading(false);
            } catch (error:any) {
                const errorMessage = error.response?.data?.message || error.message;
                toast.error(errorMessage);
                onReject();
            }
        }

        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const findByDocumentInSerpro = async () => {
        const isValid = await trigger("taxId");
        if (isValid) {
            setBureauState({ loading: true, error: false });
            try {
                const taxId = masks.cpfCnpjMask.unmask(getValues("taxId"));
                const response = await getPersonByDocumentInSerpro(taxId);
                if (response.data.erro) {
                    setBureauState({ loading: false, error: false });
                    toast.error(`${t("validations.findDocument")}`);
                    return;
                }

                const values = {
                    ...getValues(),
                    name: removeSpecialCharactersAndAccents(response.data.name),
                    dateOfBirth: parse(response.data.dateOfBirth, 'yyyy-MM-dd', new Date()),
                }

                setBureauState({ loading: false, error: false });
                reset(values);
            } catch (error:any) {
                setBureauState({ loading: false, error: true });
                const errorMessage = error.response?.data?.message || error.message;
                toast.error(errorMessage);
            }
        }
    }

    const onSubmit = async (data:any) => {

        setSubmitting(true);

        let identificationProof = data.identificationProof[0];
        if (identificationProof && !identificationProof.id) {
            const result = await toBase64(identificationProof);
            identificationProof = {
                fileOriginalName: identificationProof.name,
                fileInBase64: result,
                fileSize: identificationProof.size,
                fileType: identificationProof.type,
            }
        } else {
            identificationProof = null;
        }

        let contractProof = data.contractProof[0];
        if (contractProof && !contractProof.id) {
            const result = await toBase64(contractProof);
            contractProof = {
                fileOriginalName: contractProof.name,
                fileInBase64: result,
                fileSize: contractProof.size,
                fileType: contractProof.type,
            }
        } else {
            contractProof = null;
        }

        data = {
            ...data,
            identificationProof,
            contractProof,
        }

        try {
            await registrationUpdateCompanyAssociateAdd(data);
            toast.success('Sócio criado com sucesso.');
            onResolve();
        } catch (error:any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.addBusinessPartner")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <fieldset disabled={submitting || bureauState.loading} style={{ border: 0 }}>
                                <Grid
                                    container
                                    spacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <Controller
                                            control={control}
                                            name="typeId"
                                            render={({ field }) => (
                                                <Select
                                                    value={field.value}
                                                    onChange={(e:any) => {
                                                        field.onChange(e);
                                                        setValue('taxId', '');
                                                        setValue('name', '');
                                                    }}
                                                    inputRef={field.ref}
                                                    error={!!errors.typeId}
                                                    label={t("text.type") as string}
                                                    displayEmpty
                                                    disabled={uploading}
                                                >
                                                    <MenuItem value="1">{t("text.private")}</MenuItem>
                                                    <MenuItem value="2">{t("text.corporate")}</MenuItem>
                                                </Select>
                                            )}
                                        />
                                        <FormHelperTextStyled>
                                            {displayErrorMessage(errors.typeId?.message) as string}
                                        </FormHelperTextStyled>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={3}
                                        xl={3}
                                    >
                                        <Controller
                                            control={control}
                                            name="taxId"
                                            render={({ field }) => (
                                                <TextField
                                                    autoFocus
                                                    value={field.value}
                                                    onChange={(e) => {
                                                        e.persist();
                                                        if (typeId === "1") {
                                                            masks.cpfMask.onChange(e);
                                                        } else {
                                                            masks.cnpjMask.onChange(e);
                                                        }
                                                        field.onChange(e);
                                                        findByDocumentInSerpro();
                                                    }}
                                                    inputRef={field.ref}
                                                    label={typeId === "1" ? t("text.cpf") : t("text.cnpj")}
                                                    placeholder={typeId === "1" ? t("text.cpf") as string : t("text.cnpj") as string}
                                                    fullWidth
                                                    error={!!errors.taxId}
                                                    helperText={displayErrorMessage(errors.taxId?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={4}
                                        lg={4}
                                        xl={4}
                                    >
                                        <Controller
                                            control={control}
                                            name="name"
                                            render={({ field }) => (
                                                <TextField
                                                    value={field.value}
                                                    onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                    inputRef={field.ref}
                                                    label={typeId === "1" ? t("text.fullName") : t("text.corporateName")}
                                                    placeholder={typeId === "1" ? t("text.fullName") as string : t("text.corporateName") as string }
                                                    inputProps={{ maxLength: 100 }}
                                                    fullWidth
                                                    error={!!errors.name}
                                                    helperText={displayErrorMessage(errors.name?.message) as string}
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                    >
                                        <Controller
                                            control={control}
                                            name="shareCapital"
                                            render={({ field }) => (
                                                <CurrencyTextField
                                                    label={t("text.percentageOnEquity")}
                                                    currencySymbol="%"
                                                    decimalCharacter=","
                                                    digitGroupSeparator="."
                                                    decimalPlaces={3}
                                                    variant="outlined"
                                                    value={field.value}
                                                    onChange={(e:any, v:any) => {
                                                        field.onChange(v);
                                                    }}
                                                    inputRef={(ref:any) => {
                                                        field.ref({
                                                            focus: () => {
                                                                ref.domElement.focus();
                                                            }
                                                        });
                                                    }}
                                                    placeholder="0,00"
                                                    fullWidth
                                                    error={!!errors.shareCapital}
                                                    helperText={displayErrorMessage(errors.shareCapital?.message) as string }
                                                    disabled={uploading}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    {typeId === "1"
                                        ? <React.Fragment>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <FormControl>
                                                    <FormLabelStyled>
                                                        {t("text.isLegalRepresentative")}
                                                    </FormLabelStyled>
                                                    <Controller
                                                        control={control}
                                                        name="legalRepresentative"
                                                        render={({ field }) => {
                                                            return (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabelStyled
                                                                        value="1"
                                                                        control={<Radio />}
                                                                        label={t("text.yes")}
                                                                    />
                                                                    <FormControlLabelStyled
                                                                        value="2"
                                                                        control={<Radio />}
                                                                        label={t("text.no")}
                                                                    />
                                                                </RadioGroup>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                {errors.legalRepresentative
                                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.legalRepresentative?.message) as string }</FormHelperTextStyled>
                                                    : null
                                                }
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Grid
                                                    container
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormLabelStyled>
                                                            {t("text.areYouUsPerson")}
                                                        </FormLabelStyled>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormLabelInfoStyled>
                                                            {t("text.usPersonExplanation")}
                                                        </FormLabelInfoStyled>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <FormControl>
                                                    <Controller
                                                        control={control}
                                                        name="usPerson"
                                                        render={({ field }) => {
                                                            return (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabelStyled
                                                                        value="1"
                                                                        control={<Radio />}
                                                                        label={t("text.yes")}
                                                                    />
                                                                    <FormControlLabelStyled
                                                                        value="2"
                                                                        control={<Radio />}
                                                                        label={t("text.no")}
                                                                    />
                                                                </RadioGroup>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                {errors.usPerson
                                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.usPerson?.message) as string }</FormHelperTextStyled>
                                                    : null
                                                }
                                            </Grid>
                                            {usPerson === "1"
                                                ? <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="nif"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.nif")}
                                                                placeholder={t("text.nif") as string}
                                                                inputProps={{ maxLength: 100 }}
                                                                fullWidth
                                                                error={!!errors.nif}
                                                                helperText={displayErrorMessage(errors.nif?.message) as string}
                                                                variant="outlined"
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                                : null
                                            }
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <Grid
                                                    container
                                                    spacing={1}
                                                >
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormLabelStyled>
                                                            {t("text.isPepOrHasRelationshipWithPep")}
                                                        </FormLabelStyled>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={12}
                                                        md={12}
                                                        lg={12}
                                                        xl={12}
                                                    >
                                                        <FormLabelInfoStyled>
                                                            {t("text.pepExplanation")}
                                                        </FormLabelInfoStyled>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                            >
                                                <FormControl>
                                                    <Controller
                                                        control={control}
                                                        name="isPep"
                                                        render={({ field }) => {
                                                            return (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabelStyled
                                                                        value="1"
                                                                        control={<Radio />}
                                                                        label={t("text.pepYes")}
                                                                    />
                                                                    <FormControlLabelStyled
                                                                        value="2"
                                                                        control={<Radio />}
                                                                        label={t("text.pepNo")}
                                                                    />
                                                                </RadioGroup>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                {errors.isPep
                                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.isPep?.message) as string}</FormHelperTextStyled>
                                                    : null
                                                }
                                            </Grid>
                                            {isPep === "1"
                                                ? <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="isPepExplain"
                                                        render={({ field }) => (
                                                            <TextField
                                                                value={field.value}
                                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                                inputRef={field.ref}
                                                                label={t("text.explain")}
                                                                placeholder={t("text.explain") as string}
                                                                fullWidth
                                                                error={!!errors.isPepExplain}
                                                                helperText={displayErrorMessage(errors.isPepExplain?.message) as string}
                                                                variant="outlined"
                                                                />
                                                        )}
                                                    />
                                                </Grid>
                                                : null
                                            }
                                            {parseFloat(shareCapital) >= 25
                                                ? <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Card>
                                                        <CardHeaderTitleStyled
                                                            title={t("text.identificationDocumentWithPicture")}
                                                            subheader={t("text.identificationDocumentValidity")}
                                                        />
                                                        <Document
                                                            title={t("text.addDocument")}
                                                            register={register}
                                                            getValues={getValues}
                                                            name="identificationProof"
                                                            uploading={uploading}
                                                            setUploading={setUploading}
                                                        />
                                                        <CardActions>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                            >
                                                                {t("text.allowedFileTypesAndSize")}
                                                            </Typography>
                                                        </CardActions>
                                                    </Card>
                                                    {errors.identificationProof && <FormHelperTextStyled>{displayErrorMessage(errors.identificationProof.message) as string}</FormHelperTextStyled>}
                                                </Grid>
                                                : null
                                            }
                                        </React.Fragment>
                                        : null}
                                    {typeId === "2" && (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={2}
                                                lg={2}
                                                xl={2}
                                            >
                                                <FormControl>
                                                    <FormLabelStyled>
                                                        {t("text.isANationalCompany")}
                                                    </FormLabelStyled>
                                                    <Controller
                                                        control={control}
                                                        name="isNational"
                                                        render={({ field }) => {
                                                            return (
                                                                <RadioGroup row {...field}>
                                                                    <FormControlLabelStyled
                                                                        value="1"
                                                                        control={<Radio />}
                                                                        label={t("text.yes")}
                                                                    />
                                                                    <FormControlLabelStyled
                                                                        value="2"
                                                                        control={<Radio />}
                                                                        label={t("text.no")}
                                                                    />
                                                                </RadioGroup>
                                                            );
                                                        }}
                                                    />
                                                </FormControl>
                                                {errors.isNational
                                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.isNational?.message) as string }</FormHelperTextStyled>
                                                    : null
                                                }
                                            </Grid>
                                            {isNational === "2"
                                                ? <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <CRMCountryOptions
                                                        name="country"
                                                        control={control}
                                                        countries={countriesOptions}
                                                        error={!!errors.country}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.country?.message) as string }
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                : null
                                            }
                                            {parseFloat(shareCapital) >= 10
                                                ? <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <Card>
                                                        <CardHeaderTitleStyled
                                                            title={t("text.socialContractOrOrganizationChart")}
                                                        />
                                                        <Document
                                                            title={t("text.addDocument")}
                                                            register={register}
                                                            getValues={getValues}
                                                            name="contractProof"
                                                            uploading={uploading}
                                                            setUploading={setUploading}
                                                        />
                                                        <CardActions>
                                                            <Typography
                                                                variant="caption"
                                                                display="block"
                                                            >
                                                                {t("text.allowedFileTypesAndSize")}
                                                            </Typography>
                                                        </CardActions>
                                                    </Card>
                                                    {errors.contractProof && <FormHelperTextStyled>{displayErrorMessage(errors.contractProof.message) as string}</FormHelperTextStyled>}
                                                </Grid>
                                                : null
                                            }
                                        </>
                                    )}
                                </Grid>
                            </fieldset>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={submitting || bureauState.loading || uploading}
                            onClick={onReject}
                            label={t("text.cancel")}
                        />
                        <LoadingButton
                            type="submit"
                            label={t("text.add")}
                            form="submit-form"
                            loading={submitting || bureauState.loading || uploading}
                        />
                    </DialogActions>
                </React.Fragment>
            }
        </Dialog>
    );
}

export const registrationUpdatePartnerAddModal = create(RegistrationUpdatePartnerAddModal);
