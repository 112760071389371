import React, { useContext, useEffect, useState } from "react";
import { CheckOutlined, EditOutlined, InfoOutlined } from '@mui/icons-material';
import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableContainer,
    TableRow,
    Tooltip,
    Paper,
    Zoom,
    Alert,
    AlertTitle
} from '@mui/material';
import {
    TableCellBodyStyled,
} from '../../../styles/styles';
import { LoadingBox } from '../../../components/LoadingBox';
import { LoadingButton } from "../../../components/LoadingButton";
import { PageName } from '../../../components/PageName';
import { Button } from '../../../components/Button';
import { formatCNPJ } from '@brazilian-utils/brazilian-utils';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { RegistrationUpdatePersonStepper } from '../../../components/RegistrationUpdatePersonStepper';
import { getCorporateDataUpdatePromise } from '../../../services/updateService';
import { useTranslation } from 'react-i18next';
import { editCorporateAddressModal } from "../../../components/EditCorporateAddressModal";
import { editCorporateContactReasonModal } from "../../../components/EditCorporateContactReasonModal";
import { editCorporateFinancialModal } from "../../../components/EditCorporateFinancialModal";
import { editCompanyOverallDataModal } from "../../../components/EditCompanyOverallDataModal";
import { AuthContext } from "../../../contexts/auth";
import { registrationUpdateBankDetailAddModal } from "../../../components/RegistrationUpdateBankDetailAddModal";
import { registrationUpdateBankDetailUpdateModal } from "../../../components/RegistrationUpdateBankDetailUpdateModal";
import { registrationUpdateBankDataDelete } from "../../../services/updateService";
import { registrationUpdateAttorneyAddModal } from "../../../components/RegistrationUpdateAttorneyAddModal";
import { registrationUpdateAttorneyEditModal } from "../../../components/RegistrationUpdateAttorneyEditModal";
import { registrationUpdateAdministratorAddModal } from "../../../components/RegistrationUpdateAdministratorAddModal";
import { registrationUpdateAdministratorEditModal } from "../../../components/RegistrationUpdateAdministratorEditModal";
import { registrationUpdateAdministratorDelete } from "../../../services/updateService";
import { registrationUpdateAttorneyDelete } from "../../../services/updateService";
import { UpdateBankDetail } from "../../../components/UpdateBankDetail";
import { UpdateRelatedContact } from "../../../components/UpdateRelatedContact";
import { UpdateAttorney } from "../../../components/UpdateAttorney";
import { UpdateAdministrator } from "../../../components/UpdateAdministrator";
import { UpdatePartner } from "../../../components/UpdatePartner";
import { deleteModal } from "../../../components/DeleteModal";
import { registrationUpdatePartnerAddModal } from "../../../components/RegistrationUpdatePartnerAddModal";
import { registrationUpdatePartnerEditModal } from "../../../components/RegistrationUpdatePartnerEditModal";
import { registrationUpdateCompanyAssociateDelete } from "../../../services/updateService";
import { registrationUpdateContactAddModal } from "../../../components/RegistrationUpdateContactAddModal";
import { registrationUpdateContactEditModal } from "../../../components/RegistrationUpdateContactEditModal";
import { registrationUpdateContactRelatedUpdate } from "../../../services/updateService";
import { registrationUpdateContactRelatedDelete } from "../../../services/updateService";
import { IBankDetails } from "../../../interfaces/bankDetails";
import { IAttorney } from "../../../interfaces/attorney";
import { IPartners } from "../../../interfaces/partners";
import { IAdministrator } from "../../../interfaces/administrator";
import { IContacts } from "../../../interfaces/contacts";


export function CorporateUpdate() {
    const { handleSignOut } = useContext(AuthContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [estimatedValueId, setEstimatedValueId] = useState<string>('');
    const [size, setSize] = useState<string>('');
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [openBankDetail, setOpenBankDetail] = useState<boolean>(false);
    const [openRelatedContact, setOpenRelatedContact] = useState<boolean>(false);
    const [openPartner, setOpenPartner] = useState<boolean>(false);
    const [openAttorney, setOpenAttorney] = useState<boolean>(false);
    const [openAdministrator, setOpenAdministrator] = useState<boolean>(false);
    const [personalData, setPersonalData] = useState<any>({});
    const { user } = useContext(AuthContext);
    const { type } = user.value;

    const { handleSubmit, reset } = useForm({

    });

    const relatedContactRowClick = () => {
        setOpenRelatedContact(!openRelatedContact);
    };

    const addRelatedContactModal = async () => {
        try {
            await registrationUpdateContactAddModal();
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const editRelatedContact = async (contact: IContacts) => {
        try {
            await registrationUpdateContactEditModal({ id: contact.id });
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const deleteRelatedContact = async (contact: IContacts) => {
        try {
            const title = t("text.remove");
            const message = `${t("text.areYouSureRemoveBankDetail")} ${contact.name}?`;
            await deleteModal({
                title,
                message,
                onBeforeResolve: async () => await registrationUpdateContactRelatedDelete(contact.id),
                closeOnConflict: true,
            });
            toast.success(t("text.toast.remove"));
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const bankRowClick = () => {
        setOpenBankDetail(!openBankDetail);
    };

    const addBankDetailModal = async () => {
        try {
            await registrationUpdateBankDetailAddModal();
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const editBankDetail = async (bankDetail: IBankDetails) => {
        try {
            await registrationUpdateBankDetailUpdateModal({ id: bankDetail.id });
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const deleteBankDetail = async (bankDetail: IBankDetails) => {
        try {
            const title = t("text.remove");
            const message = `${t("text.areYouSureRemoveBankDetail")} ${bankDetail.accountNumber}?`;
            await deleteModal({
                title,
                message,
                onBeforeResolve: async () => await registrationUpdateBankDataDelete(bankDetail.id),
                closeOnConflict: true,
            });
            toast.success(t("text.toast.remove"));
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const attorneyRowClick = () => {
        setOpenAttorney(!openAttorney);
    };

    const addAttorney = async () => {
        try {
            await registrationUpdateAttorneyAddModal();
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const editAttorney = async (attorney: IAttorney) => {
        try {
            await registrationUpdateAttorneyEditModal({ id: attorney.id });
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const deleteAttorney = async (attorney: IAttorney) => {
        try {
            const title = t("text.remove");
            const message = `${t("text.removePowerOfAttorneyAreYouSure")} ${attorney.name}?`;
            await deleteModal({
                title,
                message,
                onBeforeResolve: async () => await registrationUpdateAttorneyDelete(attorney.id),
                closeOnConflict: true,
            });
            toast.success(t("text.toast.remove"));
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const administratorRowClick = () => {
        setOpenAdministrator(!openAdministrator);
    };

    const addAdministrator = async () => {
        try {
            await registrationUpdateAdministratorAddModal();
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const editAdministrator = async (administrator: IAdministrator) => {
        try {
            await registrationUpdateAdministratorEditModal({ id: administrator.id });
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const deleteAdministrator = async (administrator: IAdministrator) => {
        try {
            const title = t("text.remove");
            const message = `${t("text.areYouSureRemoveAdministrator")} ${administrator.name}?`;
            await deleteModal({
                title,
                message,
                onBeforeResolve: async () => await registrationUpdateAdministratorDelete(administrator.id),
                closeOnConflict: true,
            });
            toast.success(t("text.toast.remove"));
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const partnerRowClick = () => {
        setOpenPartner(!openPartner);
    };

    const addPartner = async () => {
        try {
            await registrationUpdatePartnerAddModal();
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const editPartner = async (partner: IPartners) => {
        try {
            await registrationUpdatePartnerEditModal({ id: partner.id });
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const deletePartner = async (partner: IPartners) => {
        try {
            const title = t("text.remove");
            const message = `${t("text.areYouSureRemovePartner")} ${partner.name}?`;
            await deleteModal({
                title,
                message,
                onBeforeResolve: async () => await registrationUpdateCompanyAssociateDelete(partner.id),
                closeOnConflict: true,
            });
            toast.success(t("text.toast.remove"));
            fetchPersonalData();
        } catch (ignored) {
        }
    }

    const onSubmit = async () => {
        setSubmitting(true);

        try {
            navigate(`/update/corporate-resume-update`);
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    useEffect(() => {
        fetchPersonalData();

    }, [])

    const fetchPersonalData = async () => {
        try {

            const [corporateData] = await Promise.all([
                getCorporateDataUpdatePromise()
            ]);

            const _responseData = corporateData.data;

            let responseData = {
                ..._responseData,
                taxId: formatCNPJ(corporateData.data.taxId),
            };

            reset(responseData);
            setEstimatedValueId(corporateData.data.estimatedValueId);
            setSize(corporateData.data.size);
            setPersonalData(corporateData.data);

            setLoading(false);
        } catch (error) {
            const errorCode = error.response?.data?.errorCode;
            if (errorCode === "12000") {
                navigate('/onboarding/order');
            } else {
                const errorMessage = error.response?.data?.message || error.message;
                toast.error(errorMessage);
            }
        }
    }

    const _editModal = async (code: string) => {
        try {

            if (code === "mc") {
                await editCorporateContactReasonModal();
            } if (code === "cd") {
                await editCompanyOverallDataModal();
            } else if (code === "end") {
                await editCorporateAddressModal();
            } else if (code === "fs") {
                await editCorporateFinancialModal();
            }

            setLoading(true);
            fetchPersonalData();

        } catch (ignored) {
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '2rem',
                }}
            >
                {loading
                    ? <LoadingBox />
                    : <React.Fragment>
                        <RegistrationUpdatePersonStepper
                            customerType={"PJ"}
                            activeStep={0} />
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            sx={{
                                background: 'var(--white)',
                                borderRadius: '4px',
                                padding: '2rem',
                                '@media(max-width: 899px)': {
                                    padding: '0rem',
                                }
                            }}
                        >
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Alert severity="info">
                                        <AlertTitle><strong>{t("text.updateAlertTitle")}</strong></AlertTitle>
                                    </Alert>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <PageName
                                        title={t("text.updateRegistrationTitle")}
                                        info={t("text.updateRegistrationSubTitle")}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                        >
                                            <Grid
                                                container
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={4}
                                                    xl={4}
                                                >

                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={12}
                                                >
                                                    <TableContainer
                                                        component={Paper}
                                                        sx={{
                                                            '@media (max-width: 1366px)': {
                                                                maxHeight: 440,
                                                            },
                                                            maxHeight: 640,
                                                        }}
                                                    >
                                                        <Table stickyHeader>
                                                            <TableBody>
                                                                <TableRow hover key="cd">
                                                                    <TableCellBodyStyled align="left">
                                                                        {t("text.overallData")}
                                                                        {personalData.overallDataExistDocumentToUpdate === "1" && (
                                                                            (personalData.socialContractStateCode === "448560001" ||
                                                                                personalData.socialContractStateCode === "448560002" ||
                                                                                personalData.lastContractualAmendmentStateCode === "448560001" ||
                                                                                personalData.lastContractualAmendmentStateCode === "448560002" ||
                                                                                personalData.bylawsStateCode === "448560001" ||
                                                                                personalData.bylawsStateCode === "448560002" ||
                                                                                personalData.lastElectionMinuteStateCode === "448560001" ||
                                                                                personalData.lastElectionMinuteStateCode === "448560002" ||
                                                                                personalData.individualMicroentrepreneurCertificateStateCode === "448560001" ||
                                                                                personalData.individualMicroentrepreneurCertificateStateCode === "448560002" ||
                                                                                personalData.entrepreneurApplicationStateCode === "448560001" ||
                                                                                personalData.entrepreneurApplicationStateCode === "448560002") ? (
                                                                                <Tooltip
                                                                                    title={t("text.partnerDocumentUpdate")}
                                                                                    arrow
                                                                                >
                                                                                    <IconButton sx={{ marginLeft: '8px', color: 'red' }}>
                                                                                        <InfoOutlined />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                personalData.socialContractStateCode === "999999999" &&
                                                                                    personalData.lastContractualAmendmentStateCode === "999999999" &&
                                                                                    personalData.bylawsStateCode === "999999999" &&
                                                                                    personalData.lastElectionMinuteStateCode === "999999999" &&
                                                                                    personalData.individualMicroentrepreneurCertificateStateCode === "999999999" &&
                                                                                    personalData.entrepreneurApplicationStateCode === "999999999" ? (
                                                                                    <Tooltip
                                                                                        title={t("text.partnerDocumentUpdate")}
                                                                                        arrow
                                                                                    >
                                                                                        <IconButton sx={{ marginLeft: '8px', color: 'green' }}>
                                                                                            <CheckOutlined />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                ) : null
                                                                            )
                                                                        )}
                                                                    </TableCellBodyStyled>
                                                                    <TableCellBodyStyled
                                                                        align="right"
                                                                        sx={{
                                                                            display: 'flex',
                                                                            gap: '1rem'
                                                                        }}
                                                                    >
                                                                        <Tooltip
                                                                            title={t("text.edit")}
                                                                            TransitionComponent={Zoom}
                                                                            arrow
                                                                        >
                                                                            <IconButton
                                                                                sx={{
                                                                                    color: 'var(--blue)'
                                                                                }}
                                                                                onClick={() => _editModal("cd")}
                                                                            >
                                                                                <EditOutlined />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCellBodyStyled>
                                                                </TableRow>
                                                                <TableRow hover key="end">
                                                                    <TableCellBodyStyled align="left">
                                                                        {t("text.address")}
                                                                    </TableCellBodyStyled>
                                                                    <TableCellBodyStyled
                                                                        align="right"
                                                                        sx={{
                                                                            display: 'flex',
                                                                            gap: '1rem'
                                                                        }}
                                                                    >
                                                                        <Tooltip
                                                                            title={t("text.edit")}
                                                                            TransitionComponent={Zoom}
                                                                            arrow
                                                                        >
                                                                            <IconButton
                                                                                sx={{
                                                                                    color: 'var(--blue)'
                                                                                }}
                                                                                onClick={() => _editModal("end")}
                                                                            >
                                                                                <EditOutlined />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCellBodyStyled>
                                                                </TableRow>
                                                                <TableRow hover key="fs">
                                                                    <TableCellBodyStyled align="left">
                                                                        {t("text.financialSituation")}
                                                                        {personalData.financialSituationExistDocumentToUpdate === "1" && (
                                                                            (personalData.patrimonyStateCode === "448560001" ||
                                                                                personalData.patrimonyStateCode === "448560002" ||
                                                                                personalData.dreStateCode === "448560001" ||
                                                                                personalData.dreStateCode === "448560002" ||
                                                                                personalData.spedStateCode === "448560001" ||
                                                                                personalData.spedStateCode === "448560002" ||
                                                                                personalData.receiptStateCode === "448560001" ||
                                                                                personalData.receiptStateCode === "448560002" ||
                                                                                personalData.defisStateCode === "448560001" ||
                                                                                personalData.defisStateCode === "448560002" ||
                                                                                personalData.billingStateCode === "448560001" ||
                                                                                personalData.billingStateCode === "448560002") ? (
                                                                                <Tooltip
                                                                                    title={t("text.incomeTax")}
                                                                                    arrow
                                                                                >
                                                                                    <IconButton sx={{ marginLeft: '8px', color: 'red' }}>
                                                                                        <InfoOutlined />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            ) : (
                                                                                personalData.patrimonyStateCode === "999999999" &&
                                                                                    personalData.dreStateCode === "999999999" &&
                                                                                    personalData.spedStateCode === "999999999" &&
                                                                                    personalData.receiptStateCode === "999999999" &&
                                                                                    personalData.defisStateCode === "999999999" &&
                                                                                    personalData.billingStateCode === "999999999" ? (
                                                                                    <Tooltip
                                                                                        title={t("text.incomeTax")}
                                                                                        arrow
                                                                                    >
                                                                                        <IconButton sx={{ marginLeft: '8px', color: 'green' }}>
                                                                                            <CheckOutlined />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                ) : null
                                                                            )
                                                                        )}
                                                                    </TableCellBodyStyled>
                                                                    <TableCellBodyStyled
                                                                        align="right"
                                                                        sx={{
                                                                            display: 'flex',
                                                                            gap: '1rem'
                                                                        }}
                                                                    >
                                                                        <Tooltip
                                                                            title={t("text.edit")}
                                                                            TransitionComponent={Zoom}
                                                                            arrow
                                                                        >
                                                                            <IconButton
                                                                                sx={{
                                                                                    color: 'var(--blue)'
                                                                                }}
                                                                                onClick={() => _editModal("fs")}
                                                                            >
                                                                                <EditOutlined />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    </TableCellBodyStyled>
                                                                </TableRow>

                                                                <UpdateBankDetail
                                                                    personalData={personalData}
                                                                    bankRowClick={bankRowClick}
                                                                    addBankDetailModal={addBankDetailModal}
                                                                    editBankDetail={editBankDetail}
                                                                    deleteBankDetail={deleteBankDetail}
                                                                    openBankDetail={openBankDetail}
                                                                />

                                                                <UpdateAttorney
                                                                    personalData={personalData}
                                                                    attorneyRowClick={attorneyRowClick}
                                                                    addAttorney={addAttorney}
                                                                    editAttorney={editAttorney}
                                                                    deleteAttorney={deleteAttorney}
                                                                    openAttorney={openAttorney}
                                                                />

                                                                <UpdateAdministrator
                                                                    personalData={personalData}
                                                                    administratorRowClick={administratorRowClick}
                                                                    addAdministrator={addAdministrator}
                                                                    editAdministrator={editAdministrator}
                                                                    deleteAdministrator={deleteAdministrator}
                                                                    openAdministrator={openAdministrator}
                                                                />

                                                                {personalData.legalNature !== "120240004" ? <UpdatePartner
                                                                    personalData={personalData}
                                                                    partnerRowClick={partnerRowClick}
                                                                    addPartner={addPartner}
                                                                    editPartner={editPartner}
                                                                    deletePartner={deletePartner}
                                                                    openPartner={openPartner}
                                                                /> : null}

                                                                <UpdateRelatedContact
                                                                    personalData={personalData}
                                                                    type={type}
                                                                    relatedContactRowClick={relatedContactRowClick}
                                                                    addRelatedContactModal={addRelatedContactModal}
                                                                    editRelatedContact={editRelatedContact}
                                                                    deleteRelatedContact={deleteRelatedContact}
                                                                    openRelatedContact={openRelatedContact}
                                                                />

                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <Button
                                                variant="text"
                                                label={t("text.continueLater")}
                                                onClick={handleSignOut}
                                                disabled={submitting}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={6}
                                            lg={6}
                                            xl={6}
                                        >
                                            <LoadingButton
                                                type="submit"
                                                variant="contained"
                                                label={t("text.nextStep")}
                                                fullWidth
                                                loading={submitting}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </form>
    )
}