import { yupResolver } from '@hookform/resolvers/yup';
import {
    Card,
    CardActions,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    Typography,
} from '@mui/material';
import React, { useEffect, useState } from "react";
import { Controller, useForm, useFieldArray, useWatch } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { create } from 'react-modal-promise';
import { toast } from 'react-toastify';
import * as yup from "yup";
import { Button } from '../Button';
import { LoadingBox } from '../LoadingBox';
import { LoadingButton } from '../LoadingButton';
import {
    CardHeaderTitleStyled,
    FormHelperTextStyled, SubSubtitlePageForm,
} from '../../styles/styles';
import { Select } from '../Select';
import { displayErrorMessage } from '../../utils/errorMessage';
import { DialogTitleStyled } from '../DeleteModal/styles';
import { getPersonalDataUpdate } from '../../services/updateService';
import { updateIncome } from '../../services/updateService';
import { IIncomeAndPowerOfAttorney } from '../../interfaces/income';
import { toBase64 } from "../../utils/fileUtils";
import Document from '../Document';
import { DocumentSubtitleAdditional } from '../DocumentSubtitleAdditional';
import DocumentAdditional from '../DocumentAdditional';
import { Add } from '@mui/icons-material';
import { stripToNull } from '../../utils/stringUtils';

const validationSchema = yup.object({
    stepIndex: yup.string(),
    estimatedValueId: yup.string(),
    incomeTaxRequested: yup.string(),
    incomeId: yup.string()
        .when("estimatedValueId", {
            is: "2",
            then: yup.string()
                .required()
        }),
    patrimonyId: yup.string()
        .when("estimatedValueId", {
            is: "2",
            then: yup.string()
                .required()
        }),
    incomeTaxProof: yup.mixed()
        .when(["estimatedValueId", "incomeTaxRequested"], {
            is: (estimatedValueId: string, incomeTaxRequested: string) => estimatedValueId === "2" && incomeTaxRequested === "1",
            then: yup.mixed()
                .test("required", "validations.fileRequired", (value) => value.length > 0)
                .test("fileSize", "validations.fileSize", (value) => {
                    return value.length && value[0].size <= 20971520;
                })
                .test("fileType", "validations.fileType", (value) => {
                    return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                }),
        }),
    incomeTaxDocuments: yup.array()
        .of(
            yup.object().shape({
                id: yup.string(),
                documentProof: yup.mixed()
                    .test("required", "validations.fileRequired", (value) => value.length > 0)
                    .test("fileSize", "validations.fileSize", (value) => {
                        return value.length && value[0].size <= 20971520;
                    })
                    .test("fileType", "validations.fileType", (value) => {
                        return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                    }),
            })
        ),
});

interface EditIncomeModalProps {
    open: boolean,
    onResolve: () => void,
    onReject: () => void,
}
const EditIncomeModal: React.FC<EditIncomeModalProps> = ({ open, onResolve, onReject }) => {
    const { t } = useTranslation();
    const [submitting, setSubmitting] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(true);
    const [uploading, setUploading] = useState<boolean>(false);

    const { control, handleSubmit, formState: { errors }, reset, register, getValues } = useForm<IIncomeAndPowerOfAttorney>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            stepIndex: "",
            productId: "",
            estimatedValueId: "",
            incomeTaxRequested: "",
            incomeId: "",
            patrimonyId: "",
            incomeTaxProof: [],
        }
    });

    const { fields, append, remove } = useFieldArray<any, string, "keyName">({
        control,
        name: "incomeTaxDocuments",
        keyName: "keyName",
    });

    const estimatedValueId = useWatch({
        control,
        name: "estimatedValueId"
    });

    const incomeTaxRequested = useWatch({
        control,
        name: "incomeTaxRequested"
    });

    useEffect(() => {
        async function fetchIncomeAndPowerOfAttorney() {
            try {
                const response = await getPersonalDataUpdate();
                const responseData = response.data;

                const document = responseData?.incomeTaxProof;
                var _incomeTaxProof: any;
                if (document?.id) {
                    _incomeTaxProof = new File(["incomeTaxProof"], document.fileOriginalName, {
                        type: document.fileType,
                    });
                    _incomeTaxProof.id = document.id;
                }

                let values = {
                    ...responseData,
                    incomeTaxProof: _incomeTaxProof ? [].concat(_incomeTaxProof) : "",
                }

                if (responseData?.incomeTaxDocuments) {
                    let incomeTaxDocuments = [];

                    for (let i = 0; i < responseData.incomeTaxDocuments.length; i = i + 1) {

                        var _documentProof: any = new File(["documentProof"], responseData.incomeTaxDocuments[i].fileOriginalName, {
                            type: responseData.incomeTaxDocuments[i].fileType,
                        });
                        _documentProof.id = responseData.incomeTaxDocuments[i].id;

                        incomeTaxDocuments.push({
                            id: responseData.incomeTaxDocuments[i].id,
                            documentTypeId: responseData.incomeTaxDocuments[i].id,
                            documentProof: _documentProof ? [].concat(_documentProof) : "",
                        });
                    }

                    values = {
                        ...values,
                        incomeTaxDocuments: incomeTaxDocuments
                    };
                }

                reset(values);
                setLoading(false);
            } catch (error: any) {
                const errorCode = error.response?.data?.errorCode;
                toast.error(errorCode);
            }
        }

        fetchIncomeAndPowerOfAttorney();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onSubmit = async (data: any) => {


        try {
            setSubmitting(true);
            const incomeTaxProof: any = data.incomeTaxProof![0];
            if (incomeTaxProof && !incomeTaxProof.id) {

                const result = await toBase64(incomeTaxProof);
                data = {
                    ...data,
                    incomeTaxProof: {
                        id: incomeTaxProof.id,
                        fileOriginalName: incomeTaxProof.name,
                        fileInBase64: result,
                        fileSize: incomeTaxProof.size,
                        fileType: incomeTaxProof.type,
                    }
                }
            } else {
                data = {
                    ...data,
                    incomeTaxProof: {},
                }
            }

            if (data?.incomeTaxDocuments) {
                let documentProofs: any[] = [];
                for (let i = 0; i < data.incomeTaxDocuments.length; i = i + 1) {
                    let documentProof = data.incomeTaxDocuments[i].documentProof[0];
                    const result = !documentProof.id ? await toBase64(documentProof) : null;
                    documentProofs.push({
                        id: documentProof.id,
                        fileOriginalName: documentProof.name,
                        fileInBase64: result,
                        fileSize: documentProof.size,
                        fileType: documentProof.type
                    });
                }

                data = {
                    ...data,
                    documents: documentProofs
                }
            }

            let value: any = {
                incomeId: data.incomeId,
                patrimonyId: data.patrimonyId,
                documents: data.documents,
                document: data.incomeTaxProof,
            }

            console.log("value");
            console.log(value);

            await updateIncome(value);
            toast.success(`${t("text.toast.successEdit")}`);
            onResolve();
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            {loading
                ? <LoadingBox />
                : <React.Fragment>
                    <DialogTitleStyled>
                        {t("text.income")}
                    </DialogTitleStyled>
                    <DialogContent dividers>
                        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    sx={{
                                        background: 'var(--white)',
                                        borderRadius: '4px',
                                        padding: '2rem',
                                        '@media(max-width: 899px)': {
                                            padding: '0rem',
                                        }
                                    }}
                                >
                                    <Grid
                                        container
                                        spacing={2}
                                    >
                                        {estimatedValueId === "2" &&
                                            <React.Fragment>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="incomeId"
                                                        render={({ field }) => (
                                                            <Select
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                inputRef={field.ref}
                                                                label={t("text.monthlyIncome") as string}
                                                                error={!!errors.incomeId}
                                                                displayEmpty
                                                            >
                                                                <MenuItem value="">
                                                                    {t("text.select")}
                                                                </MenuItem>
                                                                <MenuItem value="120240000">
                                                                    {t("text.until3Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240001">
                                                                    {t("text.from3to5Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240002">
                                                                    {t("text.from5to10Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240003">
                                                                    {t("text.from10to20Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240004">
                                                                    {t("text.over20Thousand")}
                                                                </MenuItem>
                                                            </Select>
                                                        )}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.incomeId?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sm={12}
                                                    md={6}
                                                    lg={6}
                                                    xl={6}
                                                >
                                                    <Controller
                                                        control={control}
                                                        name="patrimonyId"
                                                        render={({ field }) => (
                                                            <Select
                                                                value={field.value}
                                                                onChange={field.onChange}
                                                                inputRef={field.ref}
                                                                label={t("text.declaredPatrimony") as string}
                                                                error={!!errors.patrimonyId}
                                                                displayEmpty
                                                            >
                                                                <MenuItem value="">
                                                                    {t("text.select")}
                                                                </MenuItem>
                                                                <MenuItem value="120240000">
                                                                    {t("text.until100Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240001">
                                                                    {t("text.from100to300Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240002">
                                                                    {t("text.from300to500Thousand")}
                                                                </MenuItem>
                                                                <MenuItem value="120240003">
                                                                    {t("text.from500to1Million")}
                                                                </MenuItem>
                                                                <MenuItem value="120240004">
                                                                    {t("text.over1Million")}
                                                                </MenuItem>
                                                            </Select>
                                                        )}
                                                    />
                                                    <FormHelperTextStyled>
                                                        {displayErrorMessage(errors.patrimonyId?.message) as string}
                                                    </FormHelperTextStyled>
                                                </Grid>
                                                {incomeTaxRequested === "1"
                                                    ? <React.Fragment>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <SubSubtitlePageForm>
                                                                {t("text.attachDocument")}
                                                            </SubSubtitlePageForm>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={12}
                                                            md={12}
                                                            lg={12}
                                                            xl={12}
                                                        >
                                                            <Card>
                                                                <CardHeaderTitleStyled
                                                                    title={t("text.incomeTax")}
                                                                    subheader={t("text.sendCompleteDocument")}
                                                                />
                                                                <Document
                                                                    title={t("text.add")}
                                                                    register={register}
                                                                    getValues={getValues}
                                                                    name="incomeTaxProof"
                                                                    uploading={uploading}
                                                                    setUploading={setUploading}
                                                                />
                                                                <CardActions>
                                                                    <Typography variant="caption" display="block">
                                                                        {t("text.allowedFileTypesAndSize")}
                                                                    </Typography>
                                                                </CardActions>
                                                            </Card>
                                                            {errors.incomeTaxProof && <FormHelperTextStyled>{displayErrorMessage(errors.incomeTaxProof?.message) as string}</FormHelperTextStyled>}
                                                        </Grid>
                                                    </React.Fragment>
                                                    : null}
                                            </React.Fragment>
                                        }
                                    </Grid>
                                </Grid>
                                {fields.map((item, index) => {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            xl={12}
                                            key={index}
                                            paddingTop={"1rem"}
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                paddingBottom={"1rem"}
                                            >
                                                <DocumentSubtitleAdditional label={t("text.attachDocument")} />
                                            </Grid>
                                            <DocumentAdditional
                                                index={index}
                                                title={t("text.add")}
                                                name={`incomeTaxDocuments.${index}.documentProof`}
                                                register={register}
                                                errors={errors}
                                                remove={remove}
                                                getValues={getValues}
                                                headertitle={t("text.incomeTax") as string}
                                                subheader={t("text.sendCompleteDocument") as string}
                                                uploading={uploading}
                                                setUploading={setUploading}
                                                canRemove={fields.length === (index + 1)}
                                                step='income-powerOfAttorney-register'
                                            />
                                        </Grid>
                                    );
                                })}

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    paddingTop={"1rem"}

                                >
                                    <Button
                                        variant="outlined"
                                        label={t("text.addDocument")}
                                        startIcon={<Add />}
                                        onClick={() => append({
                                            id: "",
                                            fileOriginalName: "",
                                            fileSize: "",
                                            fileType: "",
                                            documentProof: "",
                                        })}
                                        disabled={!(fields.length <= 1) || uploading}
                                    />
                                </Grid>
                            </Grid>
                        </form >
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={submitting}
                            onClick={onReject}
                            label={t("text.cancel")}
                        />
                        <LoadingButton
                            type="submit"
                            label={t("text.saveChanges")}
                            form="submit-form"
                            loading={submitting}
                        />
                    </DialogActions>
                </React.Fragment>
            }
        </Dialog>
    );
}

export const editIncomeModal = create(EditIncomeModal);
