import { isValidCEP, isValidCPF, isValidEmail } from '@brazilian-utils/brazilian-utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, Search } from '@mui/icons-material';
import {
    Alert,
    Card, CardActions, Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    Grid, MenuItem, Radio,
    RadioGroup,
    Typography
} from '@mui/material';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { sub } from "date-fns";
import React, { useEffect, useState, useRef } from "react";
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import { create, InstanceProps } from 'react-modal-promise';
import { toast } from 'react-toastify';
import { useDidUpdate } from "rooks";
import * as yup from "yup";
import Document from '../Document';
import { getPersonByDocumentInSerpro } from '../../services/bureauService';
import { findAddressByCep } from '../../services/cepService';
import { saveAdministrator } from "../../services/clientService";
import { getAddressStatePromise } from '../../services/crmAddressState';
import { getCRMDocumentTypePromise } from '../../services/crmDocumentTypeService';
import { getOverallDataPromise } from "../../services/onboardingService";
import {
    FormControlLabelStyled,
    FormHelperTextStyled,
    FormLabelInfoStyled,
    FormLabelStyled,
    SubtitlePageForm
} from '../../styles/styles';
import { languages } from '../../utils/adapterLocaleUtils';
import { displayErrorMessage } from '../../utils/errorMessage';
import { toBase64 } from "../../utils/fileUtils";
import * as masks from "../../utils/masks";
import { Button } from '../Button';
import { CRMAddressStateOptions } from '../CRMAddressStateOptions';
import { CRMDocumentTypeOptions } from '../CRMDocumentTypeOptions';
import { DialogTitleStyled } from '../DeleteModal/styles';
import DocumentAdditional from '../DocumentAdditional';
import { DocumentAlert } from '../DocumentAlert';
import { DocumentHead } from '../DocumentHead';
import { LoadingButton } from '../LoadingButton';
import { Select } from '../Select';
import { TextField } from '../TextField';
import parse from 'date-fns/parse';
import { CurrencyTextField } from '../CurrencyTextField';
import { getSubscriptionTypesPromise } from '../../services/crmSignatureTypes';
import { SignatureTypesOptions } from '../SignatureTypesOptions/index';
import { stripToEmpty, removeSpecialCharactersAndAccents } from "../../utils/stringUtils";
import { getCRMDdiPromise } from '../../services/crmDdi';
import { CRMDdiOptions } from '../CRMDdiOptions';
import { AddressState } from '../EditAddressModal/AddressState';
import { IAdministrator } from '../../interfaces/administrator';

const validationSchema = yup.object({
    size: yup.string(),
    typeOfRepresentative: yup.string().required(),
    taxId: yup.string()
        .required()
        .transform(masks.cpfMask.transform)
        .test("validateCPF", (value) => {
            return isValidCPF(value!)
        }),
    name: yup.string()
        .min(2)
        .required(),
    dateOfBirth: yup.date()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .typeError('Erro')
        .required()
        .max(sub(new Date(), { years: 18 }), "validations.under18"),
    email: yup.string()
        .required()
        .min(10)
        .transform((curr, orig) => stripToEmpty(curr))
        .test("validateEmail", (value) => isValidEmail(stripToEmpty(value))),
    phoneDdi: yup.string().nullable().required(),
    phoneDdd: yup.string().nullable().required().matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    phone: yup.string().nullable().required().matches(/^[0-9]+$/gm, { message: "validations.numbersOnly", excludeEmptyString: true }),
    motherName: yup.string()
        .min(2)
        .required(),
    usPerson: yup.string().required(),
    nif: yup.string()
        .when("usPerson", {
            is: "1",
            then: yup.string().required()
        }),
    isPep: yup.string().required(),
    isPepExplain: yup.string()
        .when("isPep", {
            is: "1",
            then: yup.string().required()
        }),
    birthCity: yup.string().required(),
    birthState: yup.string().required(),
    hasDriverLicense: yup.string().required(),
    typeOfDocument: yup.string()
        .when("hasDriverLicense", {
            is: "2",
            then: yup.string().required()
        }),
    documentNumber: yup.string()
        .required()
        .matches(/^[a-zA-Z0-9]+$/, "validations.notSpecialCharacter"),
    documentIssuer: yup.string().required(),
    documentStateIssuer: yup.string().required(),
    documentDateOfIssue: yup.date()
        .nullable()
        .transform((curr, orig) => orig === '' ? null : curr)
        .typeError('Erro')
        .required()
        .min(new Date(1500, 0, 1))
        .max(new Date()),
    addressZipCode: yup.string()
        .required()
        .transform(masks.cepMask.transform)
        .test("validateCep", (value) => {
            return isValidCEP(value!)
        }),
    addressStreet: yup.string().required(),
    addressNumber: yup.string().required(),
    addressNeighborhood: yup.string().required(),
    addressCity: yup.string().required(),
    addressState: yup.string().required(),
    addressComplement: yup.string(),
    isAssociate: yup.string()
        .when(["typeOfRepresentative", "size"], {
            is: (typeOfRepresentative: string, size: string) => size !== "120240004" && typeOfRepresentative === "120240000",
            then: yup.string().required(),
        }),
    shareCapital: yup.string()
        .when(["typeOfRepresentative", "isAssociate"], {
            is: (typeOfRepresentative: string, isAssociate: string) => isAssociate === "1" && typeOfRepresentative === "120240000",
            then: yup.string().required()
                .test({
                    name: "shareCapital",
                    message: "validations.percentageBetween0And100",
                    test: function (value) {
                        const percentage = parseFloat(value!);
                        return percentage > 0 && percentage <= 100;
                    }
                }),
        }),
    identificationProof: yup.mixed()
        .test("required", "validations.fileRequired", (value) => value.length > 0)
        .test("fileSize", "validations.fileSize", (value) => {
            return value.length && value[0].size <= 20971520;
        })
        .test("fileType", "validations.fileType", (value) => {
            return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
        }),
    documents: yup.array()
        .of(
            yup.object().shape({
                id: yup.string(),
                documentProof: yup.mixed()
                    .test("required", "validations.fileRequired", (value) => value.length > 0)
                    .test("fileSize", "validations.fileSize", (value) => {
                        return value.length && value[0].size <= 20971520;
                    })
                    .test("fileType", "validations.fileType", (value) => {
                        return value.length && ["image/jpeg", "image/png", "image/jpg", "application/pdf", "image/bmp"].includes(value[0].type)
                    }),
            })
        ),
    subscriptionType: yup.string().required(),
},);

interface AddAdministratorModalProps extends InstanceProps<any, any> {
    open: boolean,
    onResolve: () => void,
    onReject: () => void,
}

const AddAdministratorModal = ({ open, onResolve, onReject }: AddAdministratorModalProps) => {
    const { i18n, t } = useTranslation();
    const [submitting, setSubmitting] = useState(false);
    const [cep, setCep] = useState({ loading: false, error: false });
    const [bureauState, setBureauState] = useState({ loading: false, error: false });
    const [documentTypes, setDocumentTypes] = useState([]);
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [states, setStates] = useState<AddressState[]>([]);
    const [uploading, setUploading] = useState(false);
    const proofRef = useRef<any>(null);
    const [ddis, setDdis] = useState([]);

    const { control, handleSubmit, formState: { errors }, reset, resetField, register, trigger, getValues, setFocus, setValue } = useForm<IAdministrator>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            typeOfRepresentative: "",
            taxId: "",
            name: "",
            dateOfBirth: "",
            birthCity: "",
            birthState: "",
            email: "",
            phoneDdi: "",
            phoneDdd: "",
            phone: "",
            motherName: "",
            hasDriverLicense: "",
            typeOfDocument: "",
            documentTypeId: "",
            documentNumber: "",
            documentIssuer: "",
            documentStateIssuer: "",
            documentDateOfIssue: "",
            identificationProof: [],
            addressZipCode: "",
            addressStreet: "",
            addressNumber: "",
            addressNeighborhood: "",
            addressCity: "",
            addressState: "",
            addressComplement: "",
            isAssociate: "",
            shareCapital: "",
            usPerson: "",
            nif: "",
            isPep: "",
            isPepExplain: "",
            subscriptionType: "",
            size: "",
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "documents",
        keyName: "keyName",
    });


    const isPep = useWatch({
        control,
        name: "isPep",
    });

    const usPerson = useWatch({
        control,
        name: "usPerson",
    });

    const hasDriverLicense = useWatch({
        control,
        name: "hasDriverLicense",
    });

    const typeOfRepresentative = useWatch({
        control,
        name: "typeOfRepresentative",
    });

    const typeOfDocument = useWatch({
        control,
        name: "typeOfDocument",
    });

    const isAssociate = useWatch({
        control,
        name: "isAssociate",
    });

    const size = useWatch({
        control,
        name: "size",
    });

    const ddiPhoneChanged = () => {
        setValue('phoneDdd', '');
        setValue('phone', '');
    }

    const findByCep = async () => {
        const zipCode = masks.cepMask.unmask(getValues("addressZipCode"));
        if (zipCode === "" || zipCode.length !== 8) return;
        setCep({ loading: true, error: false });
        try {
            const response = await findAddressByCep(zipCode);
            if (response.data.erro) {
                toast.error(`${t("validations.findAddress")}`);
                setCep({ loading: false, error: false });
                return;
            }
            const state = states.find(s => s.state === response.data.uf);
            const values = {
                ...getValues(),
                addressStreet: removeSpecialCharactersAndAccents(response.data.logradouro),
                addressNeighborhood: removeSpecialCharactersAndAccents(response.data.bairro),
                addressCity: removeSpecialCharactersAndAccents(response.data.localidade),
                addressState: state!.id,
                addressComplement: removeSpecialCharactersAndAccents(response.data.complemento),
            }

            setCep({ loading: false, error: false });
            reset(values);
        } catch (error) {
            setCep({ loading: false, error: true });
            console.log('error', error);
            toast.error(t("text.toast.zipCodeServiceUnavailable"));
        }
    }

    const findByDocumentInSerpro = async () => {
        const isValid = await trigger("taxId");
        if (isValid) {
            setBureauState({ loading: true, error: false });
            try {
                const taxId = masks.cpfMask.unmask(getValues("taxId"));
                const response = await getPersonByDocumentInSerpro(taxId);
                if (response.data.erro) {
                    setBureauState({ loading: false, error: false });
                    toast.error(`${t("validations.findDocument")}`);
                    return;
                }

                const values = {
                    ...getValues(),
                    name: removeSpecialCharactersAndAccents(response.data.name),
                    dateOfBirth: parse(response.data.dateOfBirth, 'yyyy-MM-dd', new Date()),
                }

                setBureauState({ loading: false, error: false });
                reset({ ...values, dateOfBirth: values.dateOfBirth?.toISOString() });
            } catch (error: any) {
                setBureauState({ loading: false, error: true });
                const errorMessage = error.response?.data?.message || error.message;
                toast.error(errorMessage);
            }
        }
    }

    const onSubmit = async (data: IAdministrator) => {
        setSubmitting(true);

        try {
            let identificationProof: any = data.identificationProof[0];
            if (identificationProof && !identificationProof.id) {
                const result = await toBase64(identificationProof);
                identificationProof = {
                    typeId: data.typeOfDocument,
                    fileOriginalName: identificationProof.name,
                    fileInBase64: result,
                    fileSize: identificationProof.size,
                    fileType: identificationProof.type,
                    number: data.documentNumber,
                    issuer: data.documentIssuer,
                    stateIssuer: data.documentStateIssuer,
                    dateOfIssue: data.documentDateOfIssue,
                }
            }

            data = {
                ...data,
                identificationProof,
            }

            if (data?.documents) {

                let documentProofs = [];
                for (let i = 0; i < data.documents.length; i = i + 1) {
                    let documentProof = data.documents[i].documentProof[0];
                    const result = !documentProof.id ? await toBase64(documentProof) : null;
                    documentProofs.push({
                        id: documentProof.id,
                        fileOriginalName: documentProof.name,
                        fileInBase64: result,
                        fileSize: documentProof.size,
                        fileType: documentProof.type
                    });
                }

                data = {
                    ...data,
                    documents: documentProofs
                }
            }

            await saveAdministrator(data);
            toast.success(`${t("text.toast.successAddAdministrator")}`);
            onResolve();
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || error.message;
            toast.error(errorMessage);
        } finally {
            setSubmitting(false);
        }
    }

    const cannotAddDocument = () => {
        return fields.length !== 0 || uploading || (hasDriverLicense === "" || (hasDriverLicense === "2" && (typeOfDocument === "")));
    }

    useEffect(() => {
        async function fetchDocumenTypes() {
            const [documentTypes, subscriptionTypes, states, ddisOptions, overallData] = await Promise.all([
                getCRMDocumentTypePromise(),
                getSubscriptionTypesPromise(),
                getAddressStatePromise(),
                getCRMDdiPromise(),
                getOverallDataPromise(),
            ]);

            setDocumentTypes(documentTypes.data);
            setSubscriptionTypes(subscriptionTypes.data)
            setStates(states.data);
            setDdis(ddisOptions.data);

            let values = {
                size: overallData.data.legalNature,
            }

            reset(values);

        }

        fetchDocumenTypes();
    }, []);

    useDidUpdate(() => {
        if (!cep.loading && !cep.error) setFocus("addressNumber", { shouldSelect: true });
    }, [cep]);

    return (
        <Dialog
            open={open}
            maxWidth="lg"
            scroll="paper"
            fullWidth
        >
            <DialogTitleStyled>
                {t("text.addAdministrator")}
            </DialogTitleStyled>
            <DialogContent dividers>
                <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
                    <fieldset disabled={submitting || cep.loading || bureauState.loading} style={{ border: 0 }}>
                        <Grid
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <SubtitlePageForm>
                                            {t("text.identificationData")}
                                        </SubtitlePageForm>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                xl={3}
                            >
                                <Controller
                                    control={control}
                                    name="typeOfRepresentative"
                                    render={({ field }) => (
                                        <Select
                                            value={field.value}
                                            onChange={field.onChange}
                                            inputRef={field.ref}
                                            error={!!errors.typeOfRepresentative}
                                            label={t("text.representativeType") as string}
                                            displayEmpty
                                        >
                                            <MenuItem value="">{t("text.select")}</MenuItem>
                                            <MenuItem value="120240000">{t("text.administrator")}</MenuItem>
                                            <MenuItem value="120240001">{t("text.director")}</MenuItem>
                                        </Select>
                                    )}
                                />
                                {errors.typeOfRepresentative && <FormHelperTextStyled>{displayErrorMessage(errors.typeOfRepresentative.message) as string}</FormHelperTextStyled>}
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                xl={3}
                            >
                                <Controller
                                    control={control}
                                    name="taxId"
                                    render={({ field }) => (
                                        <TextField
                                            autoFocus
                                            value={field.value}
                                            onChange={(e: { persist: () => void; }) => {
                                                e.persist();
                                                masks.cpfMask.onChange(e);
                                                field.onChange(e);
                                                findByDocumentInSerpro();
                                            }}
                                            inputRef={field.ref}
                                            label={t("text.cpf")}
                                            placeholder={t("text.cpf") as string}
                                            fullWidth
                                            error={!!errors.taxId}
                                            helperText={displayErrorMessage(errors?.taxId?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                            >
                                <Controller
                                    control={control}
                                    name="name"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={t("text.fullName")}
                                            placeholder={t("text.fullName") as string}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                            error={!!errors.name}
                                            helperText={displayErrorMessage(errors?.name?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                xl={3}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={languages[i18n.language as keyof typeof languages]}
                                >
                                    <Controller
                                        control={control}
                                        name="dateOfBirth"
                                        render={({ field }) => (
                                            <DatePicker
                                                value={field.value}
                                                onChange={field.onChange}
                                                inputRef={field.ref}
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: t("text.dob")
                                                        }}
                                                        label={t("text.dob")}
                                                        error={!!errors.dateOfBirth}
                                                        helperText={displayErrorMessage(errors?.dateOfBirth?.message) as string}
                                                        fullWidth
                                                        variant='outlined'
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                            >
                                <Controller
                                    control={control}
                                    name="birthCity"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={t("text.placeOfBirth")}
                                            placeholder={t("text.placeOfBirth") as string}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                            error={!!errors.birthCity}
                                            helperText={displayErrorMessage(errors?.birthCity?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                xl={3}
                            >
                                <CRMAddressStateOptions
                                    name="birthState"
                                    label={t("text.stateOfBirth")}
                                    control={control}
                                    states={states}
                                    error={!!errors.birthState}
                                />
                                <FormHelperTextStyled>
                                    {displayErrorMessage(errors.birthState?.message) as string}
                                </FormHelperTextStyled>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Controller
                                    control={control}
                                    name="email"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={field.onChange}
                                            inputRef={field.ref}
                                            label={t("text.email")}
                                            placeholder={t("text.email") as string}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                            error={!!errors.email}
                                            helperText={displayErrorMessage(errors?.email?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                            >
                                <Controller
                                    control={control}
                                    name="motherName"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={t("text.fullMotherName")}
                                            placeholder={t("text.fullMotherName") as string}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                            error={!!errors.motherName}
                                            helperText={displayErrorMessage(errors?.motherName?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                lg={2}
                                xl={2}
                            >
                                <CRMDdiOptions
                                    control={control}
                                    label={t("text.landlineDDI")}
                                    name="phoneDdi"
                                    ddis={ddis}
                                    error={!!errors.phoneDdi}
                                    ddiChanged={ddiPhoneChanged}
                                />
                                <FormHelperTextStyled>
                                    {displayErrorMessage(errors.phoneDdi?.message) as string}
                                </FormHelperTextStyled>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                lg={2}
                                xl={2}
                            >
                                <Controller
                                    control={control}
                                    name="phoneDdd"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={t("text.landlineDDD")}
                                            inputProps={{ maxLength: 3 }}
                                            fullWidth
                                            error={!!errors.phoneDdd}
                                            helperText={displayErrorMessage(errors.phoneDdd?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={2}
                                lg={2}
                                xl={2}
                            >
                                <Controller
                                    control={control}
                                    name="phone"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={t("text.landline")}
                                            inputProps={{ maxLength: 20 }}
                                            fullWidth
                                            error={!!errors.phone}
                                            helperText={displayErrorMessage(errors.phone?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <DocumentAlert
                                    hasDriverLicense={hasDriverLicense}
                                    typeOfDocument={typeOfDocument}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <FormControl>
                                    <FormLabelStyled>
                                        {t("text.hasCNH")}
                                    </FormLabelStyled>
                                    <Controller
                                        control={control}
                                        name="hasDriverLicense"
                                        render={({ field }) => {
                                            return (
                                                <RadioGroup
                                                    row
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        resetField("documentTypeId");
                                                        if (proofRef.current !== null)
                                                            proofRef.current.removeFile();
                                                        remove(0);
                                                    }}
                                                    value={field.value}
                                                //  {...field}
                                                >
                                                    <FormControlLabelStyled
                                                        value="1"
                                                        control={<Radio />}
                                                        label={t("text.yes")}
                                                        disabled={uploading}
                                                    />
                                                    <FormControlLabelStyled
                                                        value="2"
                                                        control={<Radio />}
                                                        label={t("text.no")}
                                                        disabled={uploading}
                                                    />
                                                </RadioGroup>
                                            );
                                        }}
                                    />
                                </FormControl>
                                {errors.hasDriverLicense
                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.hasDriverLicense.message) as string}</FormHelperTextStyled>
                                    : null
                                }
                            </Grid>
                            {hasDriverLicense === "2" && (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                >
                                    <CRMDocumentTypeOptions
                                        control={control}
                                        name="typeOfDocument"
                                        documentTypes={documentTypes}
                                        error={!!errors.typeOfDocument}
                                    />
                                    <FormHelperTextStyled>
                                        {displayErrorMessage(errors.typeOfDocument?.message) as string}
                                    </FormHelperTextStyled>
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                xl={3}
                            >
                                <Controller
                                    control={control}
                                    name="documentNumber"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={(hasDriverLicense === "2") ? t("text.documentNumber") : t("text.cnhNumber")}
                                            placeholder={(hasDriverLicense === "2") ? t("text.documentNumber") as string : t("text.cnhNumber") as string}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                            error={!!errors.documentNumber}
                                            helperText={displayErrorMessage(errors?.documentNumber?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={hasDriverLicense === "2" ? 2 : 3}
                                lg={hasDriverLicense === "2" ? 2 : 3}
                                xl={hasDriverLicense === "2" ? 2 : 3}
                            >
                                <Controller
                                    control={control}
                                    name="documentIssuer"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={t("text.issuingBody")}
                                            placeholder={t("text.issuingBody") as string}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                            error={!!errors.documentIssuer}
                                            helperText={displayErrorMessage(errors?.documentIssuer?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={hasDriverLicense === "2" ? 2 : 3}
                                lg={hasDriverLicense === "2" ? 2 : 3}
                                xl={hasDriverLicense === "2" ? 2 : 3}
                            >
                                <CRMAddressStateOptions
                                    name="documentStateIssuer"
                                    label={t("text.issuingState")}
                                    control={control}
                                    states={states}
                                    error={!!errors.documentStateIssuer}
                                />
                                <FormHelperTextStyled>
                                    {displayErrorMessage(errors.documentStateIssuer?.message) as string}
                                </FormHelperTextStyled>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                xl={3}
                            >
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={languages[i18n.language as keyof typeof languages]}
                                >
                                    <Controller
                                        control={control}
                                        name="documentDateOfIssue"
                                        render={({ field }) => (
                                            <DatePicker
                                                value={field.value}
                                                onChange={field.onChange}
                                                inputRef={field.ref}
                                                inputFormat="dd/MM/yyyy"
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        inputProps={{
                                                            ...params.inputProps,
                                                            placeholder: t("text.issueDate")
                                                        }}
                                                        label={t("text.issueDate")}
                                                        error={!!errors.documentDateOfIssue}
                                                        helperText={displayErrorMessage(errors?.documentDateOfIssue?.message) as string}
                                                        fullWidth
                                                        variant='outlined'
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            {(hasDriverLicense === "1" || typeOfDocument !== "")
                                ? <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Card>
                                        <DocumentHead
                                            hasDriverLicense={hasDriverLicense}
                                            typeOfDocument={typeOfDocument}
                                        />
                                        <Document
                                            ref={proofRef}
                                            register={register}
                                            getValues={getValues}
                                            title={t("text.add")}
                                            name="identificationProof"
                                            uploading={uploading}
                                            setUploading={setUploading}
                                        />
                                        <CardActions>
                                            <Typography
                                                variant="caption"
                                                display="block"
                                            >
                                                {t("text.allowedFileTypesAndSize")}
                                            </Typography>
                                        </CardActions>
                                    </Card>
                                    {errors.identificationProof && <FormHelperTextStyled>{displayErrorMessage(errors.identificationProof?.message) as string}</FormHelperTextStyled>}
                                </Grid>
                                : null
                            }
                            {fields.map((item, index) => {
                                return (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        key={index}
                                        paddingTop={"1rem"}
                                    >
                                        <DocumentAdditional
                                            index={index}
                                            title={t("text.add")}
                                            name={`documents.${index}.documentProof`}
                                            register={register}
                                            errors={errors}
                                            remove={remove}
                                            getValues={getValues}
                                            hasDriverLicense={hasDriverLicense}
                                            typeOfDocument={typeOfDocument}
                                            uploading={uploading}
                                            setUploading={setUploading}
                                        />
                                    </Grid>
                                );
                            })}

                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                paddingTop={"1rem"}

                            >
                                <Button
                                    variant="outlined"
                                    label={t("text.addDocument")}
                                    startIcon={<Add />}
                                    onClick={() => append({
                                        id: "",
                                        fileOriginalName: "",
                                        fileSize: "",
                                        fileType: "",
                                        documentProof: "",
                                    })}
                                    disabled={cannotAddDocument()}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Alert severity="info">
                                            {t("text.foreignAddressesCantBeAddedShort")}
                                        </Alert>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <SubtitlePageForm>
                                            {t("text.address")}
                                        </SubtitlePageForm>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={9}
                                lg={2}
                                xl={2}
                            >
                                <Controller
                                    control={control}
                                    name="addressZipCode"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => {
                                                e.persist();
                                                masks.cepMask.onChange(e);
                                                field.onChange(e);
                                            }}
                                            inputRef={field.ref}
                                            label={t("text.zipCode")}
                                            placeholder={t("text.zipCode") as string}
                                            inputProps={{ maxLength: 20 }}
                                            fullWidth
                                            error={!!errors.addressZipCode}
                                            helperText={displayErrorMessage(errors?.addressZipCode?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sm={6}
                                md={3}
                                lg={2}
                                xl={2}
                                sx={{
                                    marginTop: '1.3rem',
                                }}
                            >
                                <Button
                                    type="button"
                                    label={t("text.search")}
                                    variant="outlined"
                                    startIcon={<Search />}
                                    onClick={findByCep}
                                    disabled={submitting || cep.loading}
                                    fullWidth
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={9}
                                lg={5}
                                xl={5}
                            >
                                <Controller
                                    control={control}
                                    name="addressStreet"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={t("text.streetName")}
                                            placeholder={t("text.streetName") as string}
                                            inputProps={{ maxLength: 250 }}
                                            fullWidth
                                            error={!!errors.addressStreet}
                                            helperText={displayErrorMessage(errors?.addressStreet?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                xl={3}
                            >
                                <Controller
                                    control={control}
                                    name="addressNumber"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={t("text.number")}
                                            placeholder={t("text.number") as string}
                                            inputProps={{ maxLength: 10 }}
                                            fullWidth
                                            error={!!errors.addressNumber}
                                            helperText={displayErrorMessage(errors?.addressNumber?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                            >
                                <Controller
                                    control={control}
                                    name="addressComplement"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={t("text.complement")}
                                            placeholder={t("text.complement") as string}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                            >
                                <Controller
                                    control={control}
                                    name="addressNeighborhood"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={t("text.neighborhood")}
                                            placeholder={t("text.neighborhood") as string}
                                            inputProps={{ maxLength: 100 }}
                                            fullWidth
                                            error={!!errors.addressNeighborhood}
                                            helperText={displayErrorMessage(errors?.addressNeighborhood?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={9}
                                lg={9}
                                xl={9}
                            >
                                <Controller
                                    control={control}
                                    name="addressCity"
                                    render={({ field }) => (
                                        <TextField
                                            value={field.value}
                                            onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                            inputRef={field.ref}
                                            label={t("text.city")}
                                            placeholder={t("text.city") as string}
                                            inputProps={{ maxLength: 80 }}
                                            fullWidth
                                            error={!!errors.addressCity}
                                            helperText={displayErrorMessage(errors?.addressCity?.message) as string}
                                            variant='outlined'
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={3}
                                lg={3}
                                xl={3}
                            >
                                <CRMAddressStateOptions
                                    name="addressState"
                                    label={t("text.uf")}
                                    control={control}
                                    states={states}
                                    error={!!errors.addressState}
                                />
                                <FormHelperTextStyled>
                                    {displayErrorMessage(errors.addressState?.message) as string}
                                </FormHelperTextStyled>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Divider />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <SubtitlePageForm>
                                            {t("text.moreInformation")}
                                        </SubtitlePageForm>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <Divider />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <SignatureTypesOptions
                                    name="subscriptionType"
                                    label={t("text.subscriptionType")}
                                    control={control}
                                    subscriptionTypes={subscriptionTypes}
                                    error={!!errors.subscriptionType}
                                />
                                <FormHelperTextStyled>
                                    {displayErrorMessage(errors.subscriptionType?.message) as string}
                                </FormHelperTextStyled>
                            </Grid>
                            {typeOfRepresentative === "120240000" && size !== "120240004"
                                ? <React.Fragment>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={3}
                                        lg={2}
                                        xl={2}
                                    >
                                        <FormControl>
                                            <FormLabelStyled>
                                                {t("text.isPartner")}
                                            </FormLabelStyled>
                                            <Controller
                                                control={control}
                                                name="isAssociate"
                                                render={({ field }) => {
                                                    return (
                                                        <RadioGroup row {...field}>
                                                            <FormControlLabelStyled
                                                                value="1"
                                                                control={<Radio />}
                                                                label={t("text.yes")}
                                                            />
                                                            <FormControlLabelStyled
                                                                value="2"
                                                                control={<Radio />}
                                                                label={t("text.no")}
                                                            />
                                                        </RadioGroup>
                                                    );
                                                }}
                                            />
                                        </FormControl>
                                        {errors.isAssociate
                                            ? <FormHelperTextStyled>{displayErrorMessage(errors.isAssociate.message) as string}</FormHelperTextStyled>
                                            : null
                                        }
                                    </Grid>
                                    {isAssociate === "1" ? <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={2}
                                        lg={2}
                                        xl={2}
                                    >
                                        <Controller
                                            control={control}
                                            name="shareCapital"
                                            render={({ field }) => (
                                                <CurrencyTextField
                                                    label={t("text.percentageOnEquity")}
                                                    currencySymbol="%"
                                                    decimalCharacter=","
                                                    digitGroupSeparator="."
                                                    decimalPlaces={3}
                                                    variant="outlined"
                                                    value={field.value}
                                                    onChange={(e: any, v: any) => {
                                                        field.onChange(v);
                                                    }}
                                                    inputRef={(ref: any) => {
                                                        field.ref({
                                                            focus: () => {
                                                                ref.domElement.focus();
                                                            }
                                                        });
                                                    }}
                                                    placeholder="0,00"
                                                    fullWidth
                                                    error={!!errors.shareCapital}
                                                    helperText={displayErrorMessage(errors.shareCapital?.message) as string}
                                                    disabled={uploading}
                                                />
                                            )}
                                        />
                                    </Grid>
                                        : null}
                                </React.Fragment>
                                : null
                            }
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <FormLabelStyled>
                                            {t("text.areYouUsPerson")}
                                        </FormLabelStyled>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <FormLabelInfoStyled>
                                            {t("text.usPersonExplanation")}
                                        </FormLabelInfoStyled>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <FormControl>
                                    <Controller
                                        control={control}
                                        name="usPerson"
                                        render={({ field }) => {
                                            return (
                                                <RadioGroup row {...field}>
                                                    <FormControlLabelStyled
                                                        value="1"
                                                        control={<Radio />}
                                                        label={t("text.yes")}
                                                    />
                                                    <FormControlLabelStyled
                                                        value="2"
                                                        control={<Radio />}
                                                        label={t("text.no")}
                                                    />
                                                </RadioGroup>
                                            );
                                        }}
                                    />
                                </FormControl>
                                {errors.usPerson
                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.usPerson.message) as string}</FormHelperTextStyled>
                                    : null
                                }
                            </Grid>
                            {usPerson === "1"
                                ? <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Controller
                                        control={control}
                                        name="nif"
                                        render={({ field }) => (
                                            <TextField
                                                value={field.value}
                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                inputRef={field.ref}
                                                label={t("text.nif")}
                                                placeholder={t("text.nif") as string}
                                                inputProps={{ maxLength: 100 }}
                                                fullWidth
                                                error={!!errors.nif}
                                                helperText={displayErrorMessage(errors.nif?.message) as string}
                                                variant='outlined'
                                            />
                                        )}
                                    />
                                </Grid>
                                : null
                            }
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <Grid
                                    container
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <FormLabelStyled>
                                            {t("text.isPepOrHasRelationshipWithPep")}
                                        </FormLabelStyled>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                    >
                                        <FormLabelInfoStyled>
                                            {t("text.pepExplanation")}
                                        </FormLabelInfoStyled>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                            >
                                <FormControl>
                                    <Controller
                                        control={control}
                                        name="isPep"
                                        render={({ field }) => {
                                            return (
                                                <RadioGroup row {...field}>
                                                    <FormControlLabelStyled
                                                        value="1"
                                                        control={<Radio />}
                                                        label={t("text.pepYes")}
                                                    />
                                                    <FormControlLabelStyled
                                                        value="2"
                                                        control={<Radio />}
                                                        label={t("text.pepNo")}
                                                    />
                                                </RadioGroup>
                                            );
                                        }}
                                    />
                                </FormControl>
                                {errors.isPep
                                    ? <FormHelperTextStyled>{displayErrorMessage(errors.isPep.message) as string}</FormHelperTextStyled>
                                    : null
                                }
                            </Grid>
                            {isPep === "1" && (
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                >
                                    <Controller
                                        control={control}
                                        name="isPepExplain"
                                        render={({ field }) => (
                                            <TextField
                                                value={field.value}
                                                onChange={(e) => field.onChange(removeSpecialCharactersAndAccents(e.target.value))}
                                                inputRef={field.ref}
                                                label={t("text.explain")}
                                                placeholder={t("text.explain") as string}
                                                fullWidth
                                                error={!!errors.isPepExplain}
                                                helperText={displayErrorMessage(errors?.isPepExplain?.message) as string}
                                                variant='outlined'
                                            />
                                        )}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </fieldset>
                </form>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={submitting || cep.loading || bureauState.loading || uploading}
                    onClick={onReject}
                    label={t("text.cancel")}
                />
                <LoadingButton
                    type="submit"
                    label={t("text.add")}
                    form="submit-form"
                    loading={submitting || cep.loading || bureauState.loading || uploading}
                />
            </DialogActions>
        </Dialog>
    );
}

export const addAdministratorModal = create(AddAdministratorModal);