import React from "react";
import { EditOutlined, KeyboardArrowDown, KeyboardArrowUp, DeleteOutlined, Add, InfoOutlined, CheckOutlined } from '@mui/icons-material';
import {
    IconButton,
    Table,
    TableBody,
    TableRow,
    Tooltip,
    Zoom,
    Collapse
} from '@mui/material';
import {
    TableCellBodyStyled,
    TableCellTitleStyled,
} from '../../styles/styles';
import { useTranslation } from 'react-i18next';
import * as masks from "../../utils/masks";
import { IAttorney } from "../../interfaces/attorney";
import { IAdministrator } from "../../interfaces/administrator";

interface UpdateAdministratorProps {
    personalData: any,
    administratorRowClick: () => void,
    addAdministrator: () => void,
    editAdministrator: (administrator: any) => void,
    deleteAdministrator: (administrator: any) => void,
    openAdministrator: boolean
}

export function UpdateAdministrator({ personalData, administratorRowClick, addAdministrator, editAdministrator, deleteAdministrator, openAdministrator }: UpdateAdministratorProps) {

    const { t } = useTranslation();
    return (
        <>
            <TableRow>
                <TableCellBodyStyled>
                    {t("text.administrators")}
                    {personalData.administrators?.some((administrator: any) => {
                        return administrator.administratorExistDocumentToUpdate === "1" && (
                            administrator.administratorIdentificationDocumentStateCode === "448560001" ||
                            administrator.administratorIdentificationDocumentStateCode === "448560002");
                    }) ? (
                        <Tooltip
                            title={t("text.letterOfAttorney")}
                            arrow
                        >
                            <IconButton sx={{ marginLeft: '8px', color: 'red' }}>
                                <InfoOutlined />
                            </IconButton>
                        </Tooltip>
                    ) : personalData.administrators?.some((administrator: any) => {
                        return administrator.administratorExistDocumentToUpdate === "1" && administrator.administratorIdentificationDocumentStateCode === "999999999";
                    }) ? (
                        <Tooltip
                            title={t("text.letterOfAttorney")}
                            arrow
                        >
                            <IconButton sx={{ marginLeft: '8px', color: 'green' }}>
                                <CheckOutlined />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </TableCellBodyStyled>
                <TableCellBodyStyled
                    align="right"
                    sx={{
                        display: 'flex',
                        gap: '1rem'
                    }}
                >
                    <Tooltip
                        title={t("text.attorneys")}
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <IconButton
                            sx={{
                                color: 'var(--blue)'
                            }}
                            onClick={administratorRowClick}
                        >
                            {openAdministrator ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={t("text.add")}
                        TransitionComponent={Zoom}
                        arrow
                    >
                        <IconButton
                            sx={{
                                color: 'var(--blue)'
                            }}
                            onClick={addAdministrator}
                        >
                            <Add />
                        </IconButton>
                    </Tooltip>
                </TableCellBodyStyled>
            </TableRow>
            <TableRow>
                <TableCellBodyStyled style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={2}>
                    <Collapse in={openAdministrator} timeout="auto" unmountOnExit>
                        <div>
                            <Table stickyHeader>
                                <TableRow>
                                    <TableCellTitleStyled align="left">{t("text.name")}</TableCellTitleStyled>
                                    <TableCellTitleStyled align="right">{t("text.cpf")}</TableCellTitleStyled>
                                    <TableCellTitleStyled align="right">{t("text.actions")}</TableCellTitleStyled>
                                </TableRow>
                                <TableBody>
                                    {personalData.administrators != null && personalData.administrators.map((administrator: IAdministrator) => {
                                        return <TableRow hover key={administrator.id}>
                                            <TableCellBodyStyled align="left">
                                                {administrator.name}
                                                {administrator.administratorExistDocumentToUpdate === "1" && (
                                                    administrator.administratorIdentificationDocumentStateCode === "448560001" ||
                                                    administrator.administratorIdentificationDocumentStateCode === "448560002"
                                                )
                                                    && (
                                                        <Tooltip
                                                            title={t("text.partnerDocumentUpdate")}
                                                            arrow
                                                        >
                                                            <IconButton sx={{ marginLeft: '8px', color: 'red' }}>
                                                                <InfoOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                {administrator.administratorExistDocumentToUpdate === "1" && administrator.administratorIdentificationDocumentStateCode === "999999999"

                                                    && (
                                                        <Tooltip
                                                            title={t("text.partnerDocumentUpdate")}
                                                            arrow
                                                        >
                                                            <IconButton sx={{ marginLeft: '8px', color: 'green' }}>
                                                                <CheckOutlined />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                            </TableCellBodyStyled>
                                            <TableCellBodyStyled align="right">{masks.cpfMask.maskDefault(administrator.taxId)}</TableCellBodyStyled>
                                            <TableCellBodyStyled
                                                align="right"
                                                sx={{
                                                    display: 'flex',
                                                    gap: '1rem'
                                                }}
                                            >
                                                <Tooltip
                                                    title={t("text.remove")}
                                                    TransitionComponent={Zoom}
                                                    arrow
                                                >
                                                    <IconButton
                                                        sx={{
                                                            color: 'var(--red)'
                                                        }}
                                                        onClick={() => deleteAdministrator(administrator)}
                                                    >
                                                        <DeleteOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip
                                                    title={t("text.edit")}
                                                    TransitionComponent={Zoom}
                                                    arrow
                                                >
                                                    <IconButton
                                                        sx={{
                                                            color: 'var(--blue)'
                                                        }}
                                                        onClick={() => editAdministrator(administrator)}
                                                    >
                                                        <EditOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </TableCellBodyStyled>
                                        </TableRow>

                                    })}
                                </TableBody>
                            </Table>
                        </div>
                    </Collapse>
                </TableCellBodyStyled>
            </TableRow>
        </>
    );
}