import api from "./api";
import {IPurposeData} from "../interfaces/purposeData"; 
import {IAddress} from "../interfaces/address"
import { IAdministrator } from "../interfaces/administrator";
import { IContacts } from "../interfaces/contacts";

export const getPurposeDataPromise = (id:string) => api.get(`/api/client/${id}/purpose-data`, { withCredentials: true });

export const getPurposeData = async (id:string) => {
    return await getPurposeDataPromise(id);
}

export const savePurposeData = async (data:IPurposeData, id:string, updateStepIndex = true) => {
    return await api.put(`/api/client/purpose-data/${id}`, data, { withCredentials: true, headers: { 'X-UpdateStepIndex': updateStepIndex } });
}

export const savePersonalDataRegister = async (data:IPurposeData, id:string) => {
    return await api.put(`/api/client/personal-data/${id}/register`, data, { withCredentials: true });
}

export const savePersonalData = async (data:IPurposeData, id:string, updateStepIndex = true) => {
    return await api.put(`/api/client/personal-data/${id}`, data, { withCredentials: true, headers: { 'X-UpdateStepIndex': updateStepIndex } });
}

export const getPersonalDataRegisterPromise = (id:string) => api.get(`/api/client/${id}/personal-data/register`, { withCredentials: true });

export const getPersonalDataRegister = async (id:string) => {
    return await getPersonalDataRegisterPromise(id);
}

export const getPersonalDataPromise = (id:string) => api.get(`/api/client/${id}/personal-data`, { withCredentials: true });

export const getPersonalData = async (id:string) => {
    return await getPersonalDataPromise(id);
}

export const saveAddressRegister = async (data:string, id:string) => {
    return await api.put(`/api/client/address-data/${id}/register`, data, { withCredentials: true });
}

export const saveAddress = async (data:IAddress, id:string, updateStepIndex = true) => {
    return await api.put(`/api/client/address-data/${id}`, data, { withCredentials: true, headers: { 'X-UpdateStepIndex': updateStepIndex } });
}

export const getAddressRegisterPromise = (id:string) => api.get(`/api/client/${id}/address-data/register`, { withCredentials: true });

export const getAddressRegister = async (id:string) => {
    return await getAddressRegisterPromise(id);
}

export const getAddressPromise = (id:string) => api.get(`/api/client/${id}/address-data`, { withCredentials: true });

export const getAddress = async (id:string) => {
    getAddressPromise(id);
}

export const saveBankingReferences = async (data:any, id:string) => {
    return await api.put(`/api/client/banking-references/${id}`, data, { withCredentials: true });
}

export const getBankReferences = async (id:string) => {
    return await api.get(`/api/client/${id}/banking-references`, { withCredentials: true });
}

export const getBankReferencesPromise = () => api.get(`/api/bankReferences`, { withCredentials: true });

export const getBankReferencesRegisterPromise = () => api.get(`/api/bankReferences/register`, { withCredentials: true });

export const saveBankReferences = async (data:string) => {
    return await api.patch(`/api/bankReferences`, data, { withCredentials: true });
}

export const getAdditionalData = async (id:string) => {
    return await api.get(`/api/client/${id}/pep-relationship`, { withCredentials: true });
}

export const saveAdditionalData = async (data:any, id:string) => {
    return await api.put(`/api/client/pep-relationship/${id}`, data, { withCredentials: true });
}

export const getOperationPromise = (id:string) => api.get(`/api/client/${id}/employ`, { withCredentials: true });

export const getOperation = async (id:string) => {
    return await api.get(`/api/client/${id}/employ`, { withCredentials: true });
}

export const saveOperation = async (data:any, id:string) => {
    return await api.put(`/api/client/employ/${id}`, data, { withCredentials: true });
}

export const getAdministrators = async () => {
    return await api.get(`/api/client-legal-representative/client`, { withCredentials: true });
}

export const getAdministratorPromise = (id:string) => api.get(`/api/client-legal-representative/${id}`, { withCredentials: true });

export const getAdministrator = async (id:string) => {
    return await getAdministratorPromise(id);
}

export const isBoundToBusinessPartner = async (id:string) => {
    return await api.get(`/api/client-legal-representative/bound/${id}`, { withCredentials: true });
}

export const saveAdministrator = async (data:IAdministrator) => {
    return await api.post(`/api/client-legal-representative`, data, { withCredentials: true });
}

export const updateAdministratorStep = async () => {
    return await api.post(`/api/client-legal-representative/step`, {}, { withCredentials: true });
}

export const updateAdministrator = async (id:string, data:IAdministrator) => {
    return await api.patch(`/api/client-legal-representative/${id}`, data, { withCredentials: true });
}

export const deleteAdministrator = async (id:string) => {
    return await api.delete(`/api/client-legal-representative/${id}`, { withCredentials: true });
}

export const getContactReasonPromise = () => api.get(`/api/client/contact-reason`, { withCredentials: true });

export const saveContactReason = async (data:IContacts, updateStepIndex = true) => {
    return await api.patch(`/api/client/contact-reason`, data, { withCredentials: true, headers: { 'X-UpdateStepIndex': updateStepIndex } });
}

export const saveProfessionalData = async (data:any, id:string) => {
    return await api.put(`/api/client/professional-data/${id}`, data, { withCredentials: true });
}

export const getProfessionalData = async (id:string) => {
    return await api.get(`/api/client/${id}/professional-data`, { withCredentials: true })
}

export const saveDocuments = async (id:string, data:any) => {
    const options = {
        withCredentials: true,
        headers: {
            'Content-Type': 'multipart/form-data'
        },
    }
    return await api.post(`/api/client/document/${id}`, data, options);
}

export const getDocuments = async (id:string) => {
    return await api.get(`/api/client/${id}/document`, { withCredentials: true });
}

export const deleteDocument = async (id:string) => {
    return await api.delete(`/api/client-document/${id}`, { withCredentials: true });
}

export const downloadDocument = async (id:string) => {
    return await api.get(`/api/client-document/download/${id}`, {
        responseType: 'blob',
        withCredentials: true,
    });
}

export const saveRelationshipPurpose = async (data:any, id:string) => {
    return await api.put(`/api/client/relationship-purpose/${id}`, data, { withCredentials: true });
}

export const getRelationshipPurposeData = async (id:any) => {
    return await api.get(`/api/client/${id}/relationship-purpose`, { withCredentials: true })
}

export const saveTermsAndConditions = async (data:any, id:string) => {
    return await api.put(`/api/client/term-condition/${id}`, data, { withCredentials: true });
}

export const getTermsAndConditions = async (id:string) => {
    return await api.get(`/api/client/${id}/term-condition`, { withCredentials: true });
}

export const getCurrentStepByUserId = async (userId:string, accessToken:string) => {
    return await api.get(`/api/client/${userId}/step`, {
        headers: { Authorization: `Bearer ${accessToken}` }
    });
}

export const savePreviousStep = async (id:string, stepIndex:string) => {
    return await api.get(`/api/client/step-previous/${id}?stepIndex=${stepIndex}`, { withCredentials: true });
}
