import { Alert } from "@mui/material";
import { useTranslation } from "react-i18next";
import { documentInfo } from "../../utils/documentInfo";
import React from "react";

interface DocumentAlertProps {
    hasDriverLicense:string;
    typeOfDocument:string;
    update?:boolean;
}

export const DocumentAlert : React.FC<DocumentAlertProps> = ({ hasDriverLicense, typeOfDocument, update = false }) => {
    const { t } = useTranslation();

    if(update)  {

        if (hasDriverLicense === "1") {
            return <Alert severity="info">{t("text.updateYourCNHDataBelow")}</Alert>
        } else if (hasDriverLicense === "2" && typeOfDocument !== "" && typeOfDocument !== undefined) {
            const selectedDocumentInfo = documentInfo.find(d => d.id === typeOfDocument);
            return <Alert severity="info">{t(selectedDocumentInfo!.updateMessage)}</Alert>
        } else {
            return null
        }

    } else if (hasDriverLicense === "1") {
        return <Alert severity="info">{t("text.enterYourCNHDataBelow")}</Alert>
    } else if (hasDriverLicense === "2" && typeOfDocument !== "" && typeOfDocument !== undefined) {
        const selectedDocumentInfo = documentInfo.find(d => d.id === typeOfDocument);
        return <Alert severity="info">{t(selectedDocumentInfo!.message)}</Alert>
    } else {
        return null
    }
}
