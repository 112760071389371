import React from "react";
import {
  EditOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
  DeleteOutlined,
  Add,
  InfoOutlined,
} from "@mui/icons-material";
import {
  IconButton,
  Table,
  TableBody,
  TableRow,
  Tooltip,
  Zoom,
  Collapse,
} from "@mui/material";
import { TableCellBodyStyled, TableCellTitleStyled } from "../../styles/styles";
import { useTranslation } from "react-i18next";
import { IContacts } from "../../interfaces/contacts";

interface UpdateRelatedContactProps {
  type: number;
  personalData: any;
  relatedContactRowClick: () => void;
  addRelatedContactModal: () => void;
  editRelatedContact: (relatedContact: any) => void;
  deleteRelatedContact: (relatedContact: any) => void;
  openRelatedContact: boolean;
}

export function UpdateRelatedContact({
  personalData,
  type,
  relatedContactRowClick,
  addRelatedContactModal,
  editRelatedContact,
  deleteRelatedContact,
  openRelatedContact,
}: UpdateRelatedContactProps) {
  const { t } = useTranslation();
  return (
    <>

      <TableRow>
        <TableCellBodyStyled>
          {t("text.contacts")}
        </TableCellBodyStyled>
        <TableCellBodyStyled
          align="right"
          sx={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <Tooltip
            title={t("text.contacts")}
            TransitionComponent={Zoom}
            arrow
          >
            <IconButton
              sx={{
                color: "var(--blue)",
              }}
              onClick={relatedContactRowClick}
            >
              {openRelatedContact ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("text.add")} TransitionComponent={Zoom} arrow>
            <IconButton
              sx={{
                color: "var(--blue)",
              }}
              onClick={addRelatedContactModal}
            >
              <Add />
            </IconButton>
          </Tooltip>
        </TableCellBodyStyled>
      </TableRow>
      <TableRow>
        <TableCellBodyStyled
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={2}
        >
          <Collapse in={openRelatedContact} timeout="auto" unmountOnExit>
            <div>
              <Table stickyHeader>
                <TableRow>
                  <TableCellTitleStyled align="left">{t("text.name")}</TableCellTitleStyled>
                  <TableCellTitleStyled align="left">{t("text.landline")}</TableCellTitleStyled>
                  <TableCellTitleStyled align="left">{t("text.cellphone")}</TableCellTitleStyled>
                  <TableCellTitleStyled align="left">{t("text.email")}</TableCellTitleStyled>
                  <TableCellTitleStyled align="left">{type === 1 ? t("text.relationship") : t("text.positionDepartment")}</TableCellTitleStyled>
                  <TableCellTitleStyled align="right">{t("text.actions")}</TableCellTitleStyled>
                </TableRow>
                <TableBody>
                  {personalData.contacts.map((contact: IContacts) => {
                    return (
                      <TableRow hover key={contact.id}>
                        <TableCellBodyStyled align="left">{contact.name}</TableCellBodyStyled>
                        <TableCellBodyStyled align="left">{contact.phoneDdd}{contact.phone}</TableCellBodyStyled>
                        <TableCellBodyStyled align="left">{contact.cellPhoneDdd}{contact.cellPhone}</TableCellBodyStyled>
                        <TableCellBodyStyled align="left">{contact.email}</TableCellBodyStyled>
                        <TableCellBodyStyled align="left">{contact.occupation}</TableCellBodyStyled>
                        <TableCellBodyStyled
                          align="right"
                          sx={{
                            display: "flex",
                            gap: "1rem",
                          }}
                        >
                          <Tooltip
                            title={t("text.remove")}
                            TransitionComponent={Zoom}
                            arrow
                          >
                            <IconButton
                              sx={{
                                color: "var(--red)",
                              }}
                              onClick={() => deleteRelatedContact(contact)}
                            >
                              <DeleteOutlined />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={t("text.edit")}
                            TransitionComponent={Zoom}
                            arrow
                          >
                            <IconButton
                              sx={{
                                color: "var(--blue)",
                              }}
                              onClick={() => editRelatedContact(contact)}
                            >
                              <EditOutlined />
                            </IconButton>
                          </Tooltip>
                        </TableCellBodyStyled>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          </Collapse>
        </TableCellBodyStyled>
      </TableRow>
    </>
  );
}
